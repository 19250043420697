import React, { useState, useEffect } from 'react';
// API and Hook Imports
import UseQueryProject from '../../../hooks/UseQueryProject';
import { useApi } from '../../../hooks/UseApi';
import RoleApi from '../../../api/RoleApi';
import UserApi from '../../../api/UserApi';
//Desing System Imports
import { Heading, Button } from '../../../designSystem/DesignSystem';
import { SidePaneRightContent } from '../../../components/Layout';
import UserSearch from './UserSearch';
//Component Imports
import ProjectUserDetails from './ProjectUserDetails';
import AddProjectUserModal from './AddProjectUserModal';
//Helper Imports
import { isValidValue } from '../../../helpers/Helper';
import { hasPrivilege, isProjectManager, isSystemAdmin } from '../../../helpers/PriviligeHelper';
import { showErrorMes } from '../../../helpers/NotificationHelper';
import { PRIVILEGES } from '../../../constants';
import { useTranslation } from 'react-i18next';

const ProjectUserPage = () => {
  const { projectId } = UseQueryProject('projectId');
  const [selectedItem, setSelectedItem] = useState(null);
  const [userList, setUserList] = useState([]);
  const [roleList, setRoleList] = useState([]);
  const [openAddUserModal, setOpenAddUserModal] = useState(false);
  const { t } = useTranslation();

  const userLoader = useApi({
    name: 'Get all project users',
    api: UserApi.getProjectUsersWithAdmins,
    onSuccess: () => {
      setUserList(userLoader?.data);
      setSelectedItem(userLoader?.data[0]);
    },
    onError: (err) => showErrorMes(err)
  });

  const projectRoles = useApi({
    name: 'fetching project roles',
    api: RoleApi.getProjectRoleList,
    autoLoad: false,
    onSuccess: () => setRoleList(projectRoles.data),
    onError: (err) => showErrorMes(err)
  });

  const onClickItem = (item) => {
    setSelectedItem(item);
  };

  const getAllUsers = () => {
    userLoader.execute({ projectId, isAuditUsersInclude: false });
  };

  const submitCallBack = (data) => {
    getAllUsers();
    setSelectedItem(data);
  };

  useEffect(() => {
    if (projectId && (isProjectManager(projectId) || hasPrivilege(projectId, PRIVILEGES.VIEW_USER))) {
      projectRoles.execute({ projectId });
      getAllUsers();
    }
  }, [projectId]);

  return (
    <>
      <SidePaneRightContent overflow={true}>
        <SidePaneRightContent.Top>
          <Heading.Group
            type="large"
            text={t('Users')}
          >
            {(isProjectManager(projectId) || hasPrivilege(projectId, PRIVILEGES.ADD_USER) || isSystemAdmin()) && (
              <div className="flex items-center justify-between gap-4">
                <div className="flex self-end">
                  <Button
                    id="projectUserPage-add-button"
                    text={t('Add User')}
                    onClick={() => setOpenAddUserModal(true)}
                    primary
                    icon="Plus"
                  />
                </div>
              </div>
            )}
          </Heading.Group>
        </SidePaneRightContent.Top>

        <div className="grid h-full grid-cols-4 divide-x">
          <div className="col-span-1">
            <UserSearch
              selectedItem={selectedItem}
              list={userList}
              loading={userLoader.loading}
              passOnClick={(item) => onClickItem(item)}
            />
          </div>
          {isValidValue(selectedItem) && (
            <div className="col-span-3">
              <ProjectUserDetails
                projectId={projectId}
                selectedItem={selectedItem}
                allRoles={roleList}
                submitCallBack={submitCallBack}
              />
            </div>
          )}
        </div>
      </SidePaneRightContent>
      {openAddUserModal && (
        <AddProjectUserModal
          savedUsers={userList}
          isOpen={openAddUserModal}
          closeModal={() => setOpenAddUserModal(false)}
          submitCallBack={submitCallBack}
          projectId={projectId}
        />
      )}
    </>
  );
};

export default ProjectUserPage;
