import { useEffect } from 'react';
// Context Imports
import { useAppDispatch, useApp } from '../context/AppContext';
import { addRequest, removeRequest, saveProjectUsers } from '../context/ProjectActions';
// Api Imports
import UserApi from '../api/UserApi';
import { showErrorMes } from '../helpers/NotificationHelper';
import { useMemo } from 'react';
import { uniqueId } from 'lodash-es';
import { useApi } from './UseApi';

const UseProjectUsers = (projectId) => {
  const app = useApp();
  const users = app?.users || [];
  const activeRequests = app?.activeRequests || [];
  const dispatch = useAppDispatch();

  const id = useMemo(() => uniqueId(), []);

  const filterPredicate = (item) => item.projectId !== projectId && item?.type !== 'projectUsers';

  const getProjectUsersLoader = useApi({
    name: 'Get Project Users',
    api: UserApi.getProjectUsersWithAdmins,
    autoLoad: false,
    onSuccess: () => dispatch(saveProjectUsers(projectId, getProjectUsersLoader?.data)),
    onError: (err) => showErrorMes(err),
    onFinally: () => dispatch(removeRequest(filterPredicate))
  });

  useEffect(() => {
    if (projectId && users?.projectId !== projectId)
      dispatch(addRequest({ order: id, type: 'projectUsers', projectId }));
  }, [projectId]);

  useEffect(() => {
    if (activeRequests?.length) {
      const allRequestsForEntity = activeRequests
        .filter((item) => item.projectId === projectId && item.type === 'projectUsers')
        .map((item) => item.order);
      if ((allRequestsForEntity || []).indexOf(id) === 0 && users?.projectId !== projectId) {
        getProjectUsersLoader.execute({ projectId, deleted: false, isAdminInclude: true, isUserGroupInclude: true });
      }
    }
  }, [activeRequests]);

  return Array.isArray(users) ? users : Array.isArray(users?.values) ? users.values : [];
};

export default UseProjectUsers;
